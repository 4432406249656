/*@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap");*/

@font-face {
  font-family: "Archer";
  src: url("../fonts/Tajawal-Regular.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ADLSA";
  src: url("../fonts/Tajawal-Regular.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DINNextLTArabic";
  src: url("../fonts/Tajawal-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

html[dir="rtl"],
[dir="rtl"] {
  direction: rtl;
}

[dir="rtl"] body {
  font-family: "DINNextLTArabic", Tahoma;
  text-align: right;
}

html[dir="ltr"],
[dir="ltr"] {
  direction: ltr;
}

[dir="ltr"] body {
  font-family: "Archer", sans-serif;
  text-align: left;
}

.nav-pills {
  justify-content: center;
  border-bottom: 0;
  flex-direction: row;
}

.nav-pills .nav-link {
  padding: 10px 0;
  border-radius: 10px;
  background-color: #f6f1ee;
  color: #003865;
  min-width: 200px;
  text-align: center;
  margin: 0 10px;
  font-weight: bold;
}

.nav-pills .nav-link.active {
  background-color: #003865;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #ababaa;
}

html {
  /*scroll-behavior: smooth;*/
  font-size: 16px;
}

.text-500 {
  font-weight: 500 !important;
}

.text-600 {
  font-weight: 600 !important;
}

.text-20 {
  font-size: 1.75rem !important;
}

.text-18 {
  font-size: 1.125rem !important;
}

.text-15 {
  font-size: 0.93rem !important;
}

.text-16 {
  font-size: 1rem !important;
}

.text-xxs {
  font-size: 0.75rem !important;
}

.text-xs {
  font-size: 0.875rem !important;
}

.text-sm {
  font-size: 1.25rem !important;
}

.text-md {
  font-size: 1.5rem !important;
}

.text-lg {
  font-size: 2rem !important;
}

.bg-gold {
  background-color: rgb(94, 66, 54);
}

.bg-light-gold {
  background-color: rgb(94, 66, 54);
}

.bg-lighter-gold {
  background-color: #cdc2bc !important;
}

.bg-lightest-gold {
  background-color: rgb(205 194 188) !important;
}

.bg-beige {
  background-color: #e2dad7 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-biege {
  background-color: #ebddda;
}

.bg-light-gray {
  background-color: #fbfcff;
}

.bg-gray {
  background-color: #f1f3f7;
}

.hintStep {
  color: #6c7279;
  font-size: 0.875rem;
}

.alternate-row-bg > div:nth-child(2n + 0) {
  background-color: rgb(227 219 216);
}

.alternate-row-bg > div:hover {
  background-color: #cdc2bc !important;
}

.min-h-10 {
  min-height: 10rem;
}

.min-h-12 {
  min-height: 12rem;
}

.min-h-15 {
  min-height: 15rem;
}

.min-h-16 {
  min-height: 16rem;
}

.min-h-17 {
  min-height: 17rem;
}

.min-h-18 {
  min-height: 18rem;
}

.min-h-19 {
  min-height: 19rem;
}

.min-h-20 {
  min-height: 20rem;
}

.min-h-22 {
  min-height: 22rem;
}

.min-h-24 {
  min-height: 24rem;
}

.min-h-25 {
  min-height: 25rem;
}

.min-h-26 {
  min-height: 26rem;
}

.min-h-28 {
  min-height: 28rem;
}

.min-h-30 {
  min-height: 30rem;
}

.min-h-32 {
  min-height: 32rem;
}

.color-grey {
  color: #6c7279 !important;
}

.color-grey h2 {
  color: #000 !important;
}

.color-black {
  color: #000 !important;
}

.color-gold {
  color: #003865 !important;
}

.color-light-gold {
  color: #003865 !important;
}

.color-gray {
  color: #003865 !important;
}

.color-red {
  color: #e65454 !important;
}

.color-white {
  color: #fff !important;
}

.border-bottom-white {
  border-bottom: 2px solid #fff;
}

.border-right-white {
  border-right: 2px solid #fff;
}

/**header**/

.boder-obaque {
  border-color: rgb(255, 255, 255, 0.1) !important;
}

[dir="rtl"] {
  text-align: right;
}

[dir="ltr"] {
  text-align: left;
}

body {
  color: #161616;
  background: #fbfcff;
}

body,
a {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

a {
  color: #003865;
  text-decoration: none;
}

a:hover {
  color: #000000;
  text-decoration: none;
}

input:active,
input:hover,
a:active,
a:hover {
  outline: 0;
}

input:focus,
select:focus,
textarea:focus,
input.focus,
select.focus,
.custom-select:focus,
textarea.focus,
.form-control:focus {
  box-shadow: none !important;
  border-color: #003865;
}

a:active,
a:focus,
a:hover {
  text-decoration: underline;
}

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
}

.file input {
  min-width: 20rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: none;
  font-size: 0;
}

.file-custom:before {
  position: absolute;
  top: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 0.075rem solid #ddd;
}

[dir="ltr"] .file-custom:before {
  right: -0.075rem;
  border-radius: 0 1.5rem 1.5rem 0;
  content: "Browse";
  font-size: 0.875rem;
}

[dir="rtl"] .file-custom:before {
  left: -0.075rem;
  border-radius: 1.5rem 0 0 1.5rem;
  content: "تصفح";
  font-size: 0.875rem;
}

[dir="ltr"] .file .file-custom::after {
  content: "Browse to upload a file";
  font-size: 0.875rem;
}

[dir="rtl"] .file .file-custom::after {
  content: "تصفح لتحميل ملف";
  font-size: 0.875rem;
}

.file {
  border: 0;
}

.file .file-custom {
  border-radius: 1.5rem;
  box-shadow: none;
  border: 1px solid #d8d8d8;
  color: #000;
}

input.form-control,
select.form-control,
select.custom-select,
textarea.form-control,
.custom-file-label,
.custom-file-label {
  border: 1px solid #d8d8d8;
  border-radius: 1rem;
  color: #000;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
  border: 1px solid rgba(205, 194, 188, 0.5);
  color: rgba(107, 79, 68, 0.8);
}

.readonly {
  background-color: #fff;
  opacity: 1;
  border: 1px solid rgba(205, 194, 188, 0.5);
  color: rgba(107, 79, 68, 0.8);
  min-height: calc(1.5em + 0.75rem + 2px);
  border-radius: 1.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
}

.react-datepicker-wrapper {
  display: block;
}

[dir="ltr"] .react-datepicker-wrapper input {
  background: url("../images/calendar-inactive.png") no-repeat 96% 41%;
}

[dir="rtl"] .react-datepicker-wrapper input {
  background: url("../images/calendar-inactive.png") no-repeat 3% 44%;
}

.form-control:disabled[type="date"] {
  position: relative;
}

.form-control:disabled[type="date"]::after,
.form-control[readonly][type="date"]::after {
  position: absolute;
  right: 1rem;
  content: "\f133";
  font: normal normal normal 14px/1 FontAwesome;
}

input.form-control,
select.form-control,
select.custom-select,
.custom-file-input,
.custom-file-label {
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 1.5rem;
}

textarea.form-control {
  min-height: 7rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-brand {
  color: #8d2232 !important;
}

.bg-brand {
  background-color: #8d2232 !important;
}

.border-brand {
  border-color: #8d2232 !important;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active {
  background-color: #003865 !important;
  border-color: #003865 !important;
  border-radius: 1rem;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  min-width: 120px;
  margin-bottom: 1rem;
}

.btn-primary:hover {
  background-color: #8a8d8f !important;
  border-color: #8a8d8f !important;
  text-decoration: none;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 79, 68, 0.15);
  text-decoration: none;
}

.col-container {
  display: flex;
}

.col-container .box {
  flex: 1;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #003865;
  background-color: #003865;
}

.formGrp p {
  color: #003865;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #003865;
  border-color: #003865;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #adb5bd;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.topHeader {
  padding: 0.25rem 0;
}

.topLeftIcons {
}

.topLeftIcons a {
  font-size: 0.8rem;
  line-height: 1.4rem;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #003865;
  color: #003865;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  margin: 0 1px;
}

.topLeftIcons a:hover {
  background: #003865;
  color: #fff;
  text-decoration: none;
}

.topLeftIcons a i {
  font-size: 1rem;
}

.topRight {
  text-align: right;
}

.topRight a {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  color: #000;
  font-size: 0.8rem;
  font-weight: 600;
}

.header {
  background: #003865 top right no-repeat;
  background-size: contain;
  color: #fff;
}

.header a {
  color: #fff;
}

.log-out {
  line-height: normal;
  vertical-align: middle;
}

.header .logo {
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  padding: 1.5rem 0;
}

.header .navbar {
  padding-left: 0;
  padding-right: 0;
}

.header .logo img {
  max-width: 10rem;
  margin-right: 1rem;
}

.header .navbar-nav .nav-link,
.welcomeText {
  font-size: 0.85rem;
  font-weight: 300;
}

.welcomeText {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  text-align: right;
}

.header .navbar-nav .nav-link {
  text-align: center;
  padding-bottom: 0.25rem;
  transition: all 0.25s;
  height: 4rem;
}

.header .navbar-nav .nav-item {
  display: flex;
  align-items: center;
}

.header .navbar-nav .nav-item a {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.header .navbar-nav .nav-item .fa.fa-bell.unread {
  transform: rotate(30deg);
}

.unread {
  position: relative;
}

.unread::after {
  position: absolute;
  top: 0rem;
  right: -0.5rem;
  content: "";
  width: 0.35rem;
  height: 0.35rem;
  background: #e22525;
  border-radius: 50%;
}

[dir="rtl"] .box-list .unread::after {
  left: -0.5rem;
  right: auto;
}

#dataList .unread::after {
  position: absolute;
  top: 0rem;
  left: -0.5rem;
  content: "";
  width: 0.35rem;
  height: 0.35rem;
  background: #e22525;
  border-radius: 50%;
}

.fa-envelope.unread::after {
  top: 3px;
  right: 0;
}

.header .navbar-nav .nav-item .fa.fa-bell.unread::after {
  top: 0.1rem;
  right: 0.1rem;
  width: 0.5rem;
  height: 0.5rem;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-toggler {
  color: #fff;
}

.header .navbar-nav .nav-item:first-child a {
  padding-left: 0;
}

.header .navbar-nav .nav-item:last-child a {
  padding-right: 0;
}

.nav-item .rounded-circle {
  width: 25px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
}

.nav-item .rounded-circle:hover {
  background: #000;
}

.header .navbar-nav .nav-link img {
  display: block;
  margin: auto;
  max-height: 2rem;
  margin-bottom: 0.25rem;
  /* margin-top: 0.5rem; */
  transition: all 0.25s;
}

.header .navbar-nav .nav-link:hover img {
  margin-bottom: 0.5rem;
  margin-top: 0rem;
}

.header .navbar-nav .nav-item a i {
  transition: all 0.25s;
  display: inline-block;
  padding: 0.15rem 0.2rem 0.25rem 0.2rem;
  line-height: 1;
  border-radius: 50%;
}

.breadcrumb-section {
  border-bottom: 1px solid #f1f3f7;
}

.breadcrumb-section .breadcrumb {
  background: none;
  padding: 0.25rem 0;
  margin: 0;
  font-size: 0.9rem;
}

.main-section {
  padding: 1rem;
  min-height: 69.3vh;
}

.box {
  border-radius: 1.5rem;
  padding: 1.15rem;
  margin-bottom: 1rem;
  /* border: solid 2px #f1f3f7; */
  background-color: #ffffff;
  color: #000;
  box-shadow: 0 0 1px 1px rgb(241 243 247 / 0.25);
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(60, 60, 67, 0.2);
  padding-bottom: 0.5rem;
}

.box h2,
.box h3,
.box h4,
.box h5,
.popup-box h2,
.popup-box h3,
.popup-box h4,
.popup-box h5 {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.box .bg-beige h3,
.popup-box .bg-beige h3 {
  color: #003865;
  font-size: 1rem;
  /* text-transform: capitalize; */
  font-weight: 600;
}

a.box:hover {
  text-decoration: none;
  background-color: #f1f3f7;
}

.box-brief {
  color: #000000;
  font-size: 0.875rem;
}

.box-date {
  color: #6c7279;
  font-size: 0.875rem;
}

.box h2,
.popup-box h2 {
  font-size: 1.6rem;
  letter-spacing: 0;
  margin: 0.25rem 0;
}

.box h2 small,
.box h4,
.box h5,
.popup-box h2 small,
.popup-box h4,
.popup-box h5 {
  font-size: 0.9rem;
  font-weight: 600;
}

.inner-box {
  border-radius: 1rem;
  padding: 1.5rem 0.5rem;
  margin: 1rem 0 0.5rem 1rem;
  box-shadow: 0 0px 10px 1px rgba(173, 173, 173, 0.125);
  border: solid 1px #f1f3f7;
  background-color: #ffffff;
  transition: all 1s;
}

.inner-box:hover {
  box-shadow: 0 0px 10px 2px rgba(173, 173, 173, 0.25);
  border: solid 1px rgba(173, 173, 173, 1);
}

.inner-box .ib-icon img {
  margin-inline-start: -1.75rem;
  min-width: 2.5rem;
}

.popup-box .ib-icon img {
  margin-inline-start: -1.75rem;
  min-width: 3rem;
}

.inner-box a.ib-text {
  display: block;
  color: #000;
}

.inner-box a.ib-text:hover {
  color: #4846b2;
  text-decoration: none;
}

.inner-box .ib-text h5,
.popup-box .ib-text h5 {
  font-weight: 500;
  font-size: 0.9rem;
  display: inline-block;
  background: #003865;
  color: #fff;
  padding: 0.1rem 0.25rem;
  line-height: normal;
  transition: all 1s;
  margin-top: 3px;
}

.inner-box a.ib-text:hover h5 {
  background: #000;
}

.sidebar-container .box {
  padding: 1rem 0;
}

.sidebar-container .box .box-header {
  padding: 0.5rem 1rem;
}

.sidebar-container .carousel-indicators {
  margin-bottom: 0;
  margin-top: 1rem;
  bottom: -1.5rem;
}

.sidebar-container .carousel-indicators li {
  width: 1rem;
  height: 1rem;
  margin: 0.5rem 0.25rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #003865;
  opacity: 1;
  border-radius: 50%;
}

.sidebar-container .carousel-indicators li.active {
  background-color: #003865;
}

.login-container.box {
  padding: 0;
}

.login-container .box-header {
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;
  background-color: #f1f3f7;
  border: 0;
}

.box-body {
  padding: 1.5rem;
}

.forgotLink {
  color: #000;
  font-size: 0.8rem;
}

.forgotLink:hover {
  color: #8d2232;
}

.input-validation-error {
  margin-top: 10px;
  border: 1px solid #ff0000;
  background-color: #ffeeee;
  padding: 2px 7px;
  border-radius: 10px;
}

.register-container {
  background: #f1f3f7 url(../images/bg1.png) right top no-repeat;
  background-size: cover;
  color: #000000;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.appBox-container {
  background: #ffffff url(../images/appdownload_img.png) center center no-repeat;
  background-size: cover;
  padding: 0;
}

.appBox-container .box-body {
  background: rgb(0 119 139 / 10%);
  padding: 1rem 2rem;
  border-radius: 1rem;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.appImg {
  max-height: 2.5rem;
}

.loginBottom.box {
  border: 0;
  box-shadow: none;
}

ul.box-list {
  padding: 0;
  margin: 0;
  list-style: none;
  color: #003865;
  /*   margin-bottom: 1rem;
 */
}

.box-list-sub-header {
  color: #6c7279;
  text-transform: uppercase !important;
}

ul.box-list li {
  border-bottom: 1px solid rgba(60, 60, 67, 0.2);
}

ul.box-list li:last-child {
  border-bottom: 0;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

ul.box-list > li > a,
.box-list-details {
  color: #003865;
  padding: 0.5rem 1rem;
}

ul.box-list h5 {
  font-size: 0.95rem;
  font-weight: 500;
  color: rgb(29 29 38 / 0.7);
  text-transform: none;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.box-list-details .actionIcon {
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  color: #003865;
  background-color: rgb(246 241 239);
  padding: 0.125rem;
  min-height: 2rem;
  border-radius: 0.25rem;
  margin: 0.125rem 0;
}

.box-list-details .actionIcon i {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid #003865;
  color: #003865;
  line-height: 2rem;
  text-align: center;
  font-size: 1.25rem;
  transition: all 0.5s;
  margin: 0 0.15rem;
}

.box-list-details .actionIcon:hover i {
  background-color: #8d2232;
  color: #fff;
}

.box-list-details .actionIcon img {
  min-width: 1.5rem;
  max-height: 1.75rem;
  padding: 0.25rem;
  border-radius: 0.4rem;
  border: 1px solid #003865;
  background-color: #003865;
  text-align: center;
  transition: all 0.5s;
  margin: 0 0.15rem;
}

.box-list-details .actionIcon:hover img {
  background-color: #8d2232;
  border: 1px solid #8d2232;
}

ul.box-list h4 {
  text-transform: none;
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}

ul.box-list h4 small,
ul.box-list p {
  color: rgb(29 29 38 / 0.65);
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
}

ul.box-list a:hover {
  text-decoration: none;
  /* color: #8d2232;
  background: #fbfcff; */
}

ul.box-list a:hover h4 small,
ul.box-list a:hover p {
  color: #003865;
}

.viewLink {
  font-size: 0.9rem;
}

.viewLink:hover {
  text-decoration: none;
}

.viewLink i {
  margin-inline-start: 0.25rem;
  transition: all 0.2s;
}

.viewLink:hover i {
  margin-inline-start: 0.1rem;
  color: #003865;
}

.topHeader,
.header,
.breadcrumb-section,
.main-section {
  padding-left: 1rem;
  padding-right: 1rem;
}

.loginSideBoxBoxes .inner-box {
  margin-left: 2rem;
}

.loginSideBoxBoxes .inner-box .ib-icon img {
  min-width: 3.5rem;
  max-width: 3.5rem;
  margin-inline-start: -2.25rem;
}

.footer {
  background: #003865;
  color: #ffffff;
  margin-top: 2rem;
}

.belowFooter {
  letter-spacing: 0.41px;
  padding: 1rem 0.5rem;
  text-align: center;
  font-size: 0.75rem;
}

.belowFooter a {
  color: #fff;
}

.scrollabel-modal-box {
  max-height: 70vh;
  overflow-x: auto;
}

.myModal .modal-header {
  background: #003865 top left no-repeat;
  background-size: contain;
  color: #fff;
  padding-top: 1.5rem;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
  border: 0;
}

.myModal .modal-header .close {
  color: #fff;
  opacity: 1;
  font-weight: 300;
  font-size: 1.75rem;
  padding: 0;
  margin: 0;
  line-height: 1rem;
}

.myModal .modal-content {
  border-radius: 1rem;
  background-color: #fafbfd;
  border: 0;
  padding-bottom: 0.5rem;
}

.myModal .modal-body {
  padding: 1.5rem;
}

.myModal .modal-header .ib-icon img {
  max-height: 2rem;
  margin: 0 0.25rem;
}

.myModal .modal-header .ib-text h4,
.myModal .modal-header .ib-text h5 {
  display: inline-block;
  font-size: 1.15rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.myModal .modal-header .ib-text h4 {
  margin: 0 0.5rem;
}

.myModal .modal-header .ib-text h5 {
  font-size: 1rem;
  background: #fff;
  color: #000;
  padding: 0.15rem 0.25rem;
  line-height: 0.9rem;
}

.modal-box {
  padding: 0;
}

.modal-box-scrollable {
  max-height: 400px;
  border-radius: 1.5rem;
  overflow-y: auto;
}

.maxH-520 {
  max-height: 520px;
}

.modal-box ul.box-list > li > a:hover {
  /* background-color: inherit; */
  text-decoration: none;
}

.modal-box ul.box-list > li > a:active,
.modal-box ul.box-list > li > a:focus {
  text-decoration: none;
}

.modal-box .box-list li {
  padding-left: 15px;
  padding-right: 15px;
}

.modal-box .box-list li:nth-child(odd) {
  background-color: rgb(0 56 101 / 40%) !important;
}

.modal-box .box-list li:nth-child(even) {
  background-color: rgb(162 170 173 / 40%) !important;
}

.curr-icon {
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
}

.modal-box .box-list h5 {
  color: #6c7279;
  text-transform: uppercase;
  margin: 0;
}

.modal-box ul.box-list > li > a {
  padding: 0.5rem;
}

.modal-box ul.box-list > li > a:active {
  text-decoration: none;
}

.modal-box label {
  text-transform: uppercase;
  font-weight: 500;
  color: #6c7279;
  font-size: 0.9rem;
}

.modal-box label.longText {
  min-height: 43px;
}

.modal-header-text {
  flex: 1;
}

.modal-header-text a {
  color: #fff;
}

.modal-header-text h4 {
  font-size: 1rem;
}

.modal-header-text h5 {
  font-size: 0.75rem;
  font-weight: 300;
  margin-bottom: 0.15rem;
}

.table-box {
  padding: 0;
}

.table-box table {
  font-size: 0.9rem;
  color: #000;
  border-radius: 1rem 1rem 0 0;
}

.table-box table th {
  text-transform: uppercase;
  border-top: 0;
  background-color: #003865 !important;
  vertical-align: middle;
  color: #fff;
}

[dir="ltr"] .table-box table th:first-child {
  border-radius: 1rem 0 0 0;
}

[dir="ltr"] .table-box table th:last-child {
  border-radius: 0 1rem 0 0;
}

[dir="rtl"] .table-box table th:first-child {
  border-radius: 1rem 0 0 0;
  border-radius: 0 1rem 0 0;
}

[dir="rtl"] .table-box table th:last-child {
  border-radius: 1rem 0 0 0;
}

[dir="rtl"] .offset-2 {
  margin-right: 16.666667%;
  margin-left: 0;
}

/*.table-box .table-striped tbody tr:nth-of-type(odd)  {background-color: #f6f1ee;}*/

.table-box {
  overflow: auto;
}

.table-box .table-striped tbody tr {
  background-color: #fff;
  color: #003865;
}

.table-box .table-striped tbody:nth-child(2n + 1) tr {
  background-color:red;
}

.table-striped tbody tr:nth-of-type(odd){
  background-color:#E0E0E0 !important; 
}

.headRow label,
.headRow label a,
.resetBtn,
.box-body label {
  font-size: 0.8rem;
  text-transform: none;
  color: #6c7279;
  display: block;
  line-height: 1.5rem;
}

.headRow label a,
.resetBtn {
  color: #8d2232;
  border: 0;
  background: transparent;
  padding: 0;
}

.formGrp label {
  margin: 0;
}

.resetBtn {
  font-size: 0.75rem;
}

.maxSizeBtn {
  max-width: 12rem;
  border-radius: 20rem;
}

[dir="rtl"] .maxSizeBtn {
  max-width: 11rem;
}

.filter-box {
  margin-bottom: 1.25rem;
}

#newReqFields {
  margin-top: 1rem;
}

.filter-box input.form-control,
.filter-box select.form-control,
.filter-box select.custom-select,
.filter-box textarea.form-control,
.filter-box .custom-file-label,
.filter-box .custom-file-label {
  color: #000;
  font-size: 0.9rem;
}

.input-group-search {
  position: relative;
}

.form-group {
  display: flex;
  flex-flow: column;
  /* justify-content: space-between; */
}

.input-group-search .input-group-prepend-search {
  position: absolute;
  top: 6px;
  color: #cfcfcf;
}

[dir="ltr"] .input-group-search .input-group-prepend-search {
  left: 10px;
}

[dir="rtl"] .input-group-search .input-group-prepend-search {
  right: 10px;
}

.input-group-search .form-control {
  padding: 0.375rem 2rem;
}

input::placeholder {
  color: #adadad !important;
}

.form-control.w-50 {
  width: 48% !important;
  display: inline-block;
}

.navbar-toggler {
  background: #8d2232;
  color: #fff;
}

.showmore-div {
  margin-top: -1rem;
  text-align: center;
}

.show-more {
  cursor: pointer;
}

.shown {
  display: block;
}

.hidden {
  display: none;
}

tr.shown {
  display: table-row;
}

.collapseRow td {
  padding: 0;
  border: 0;
}

.collapseRow .collapse.show {
  border-bottom: 1px solid #e2dad7;
}

.clickRow .caretArrow {
  text-align: right;
}

.clickRow .caretArrow .fa-caret-right:before {
  content: "\f0da";
}

.clickRow[aria-expanded="true"] .caretArrow .fa-caret-right:before {
  content: "\f0d7";
  color: #8d2232;
}

.clickRow[aria-expanded="true"] {
  color: #8d2232;
  text-decoration: none;
}

.actionScrollButtons {
  text-align: center;
}

.actionScrollButtons a {
  display: inline-block;
  border: 1px solid #003865;
  color: #003865;
  margin: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
}

.actionScrollButtons a:hover {
  border: 1px solid #003865;
  color: #003865;
}

.exportExcel {
  text-align: right;
}

.exportExcel a {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
}

.exportExcel a img {
  max-width: 1.25rem;
}

.exportExcel a:hover {
  text-decoration: none;
}

.status-badge {
  display: inline-block;
  padding: 0.2rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  background: #fff;
  color: #000;
  text-transform: uppercase;
  min-width: 6rem;
  text-align: center;
  /*border: 1px solid #f6f1ee;*/
  font-weight: 600;
}

.status-badge-small {
  display: inline-block;
  padding: 0.2rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  background: #fff;
  color: #000;
  text-transform: uppercase;
  min-width: 3rem;
  text-align: center;
  /*border: 1px solid #f6f1ee;*/
  font-weight: 600;
}

.icon-badge {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 5px 0 5px;
  padding: 0;
  border: solid 1px #003865;
  background-color: #003865;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  vertical-align: middle;
}

.icon-badge-text {
  color: #003865;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.icon-badge i {
  vertical-align: middle;
}

.status-badge-lg {
  padding: 0.15rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  margin: 0.125rem;
}

.btnOutlineWhite {
  display: inline-block;
  padding: 0.25rem 1rem;
  margin: 0 0.25rem;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 2rem;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.btnOutlineWhite i.fa {
  font-size: 1rem;
  margin: 0 0.25rem;
}

[dir="ltr"] .btnOutlineWhite i.fa {
  margin: 0 0.25rem 0 0;
}

[dir="rtl"] .btnOutlineWhite i.fa {
  margin: 0 0 0 0.25rem;
}

.btnOutlineWhite:hover {
  background: #fff;
  color: #8d2232;
  text-decoration: none;
}

.bg-icon {
  background: #f1f3f7;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  text-align: center;
  line-height: 2rem;
}

.modal-open {
  padding-right: 17px !important;
}

.download-link {
  border-radius: 10px;
}

.download-link a:hover {
  background-color: #6688A3 !important;
}

.download-link a {
  padding: 8px 13px;
  background: #003865;
  margin: 0 !important;
}

[dir="ltr"] .download-link a:first-child {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  opacity: 0.8;
}

[dir="ltr"] .download-link a:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

/**managment**/

.analysis-accordion .card {
  border: 0;
}

.analysis-accordion .card > .card-header {
  margin-bottom: 0;
}

[dir="ltr"] .analysis-accordion .card {
  margin-left: 2rem;
}

[dir="rtl"] .analysis-accordion .card {
  margin-right: 2rem;
}

.analysis-accordion .card-header {
  background-color: transparent;
}

/**Admin**/

.input-group-text {
  border-radius: 1.5rem;
  background-color: #003865;
  color: #fff;
}

/**rtl***/

[dir="rtl"] .text-right,
[dir="rtl"] .text-md-right {
  text-align: left !important;
}

[dir="rtl"] .text-left,
[dir="rtl"] .text-md-left {
  text-align: right !important;
}

[dir="rtl"] .download-link i:first-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  opacity: 0.8;
}

[dir="rtl"] .download-link i:last-child {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

[dir="rtl"] .fa-chevron-left,
[dir="rtl"] .fa-chevron-right,
[dir="rtl"] .fa-arrow-right,
[dir="rtl"] .fa-caret-right,
[dir="rtl"] .fa-sign-out {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.EN {
  font-family: "Archer";
}

.Ar {
  font-family: "DINNextLTArabic";
  direction: rtl;
  text-align: right;
}

ul {
  list-style-type: disc;
  padding-inline-start: 20px;
}

ol {
  padding-inline-start: 25px;
}

ul ul {
  list-style-type: circle;
}

.MuiSvgIcon-root {
  fill: #ccc !important;
  width: 1.9em !important;
  height: 1.5em !important;
  font-size: 1.3rem !important;
}

#dataList .fa-chevron-right {
  color: #000;
}

/* Media Queries */

@media all and (min-width: 576px) {
  [dir="rtl"] .text-sm-right {
    text-align: left !important;
  }
  [dir="rtl"] .text-sm-left {
    text-align: right !important;
  }
  .modal-dialog {
    max-width: 80%;
  }
  .modal-lg {
    max-width: 70%;
  }
  .modal-xl {
    max-width: 90%;
  }
  .offset-sm-0 {
    margin-left: 0;
    margin-right: 0 !important;
  }
}

@media all and (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    justify-content: flex-end;
  }
  .modal-dialog {
    max-width: 80%;
  }
}

@media all and (min-width: 992px) {
  .modal-dialog {
    max-width: 58%;
  }
  .modal-lg {
    max-width: 70%;
  }
  .modal-xl {
    max-width: 90%;
  }
}

@media all and (min-width: 1024px) {
  .modal-dialog {
    max-width: 55%;
  }
  .modal-lg {
    max-width: 70%;
  }
  .modal-xl {
    max-width: 90%;
  }
}

@media all and (min-width: 1200px) {
  /*.inner-boxes {max-width: 84%;}*/
  .modal-dialog {
    max-width: 70%;
  }
  .modal-lg {
    max-width: 70%;
  }
  .modal-xl {
    max-width: 70%;
  }
}

@media all and (min-width: 1440px) {
  html {
    font-size: 16px;
  }
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}

@media all and (min-width: 1600px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1320px;
  }
}

@media all and (min-width: 1920px) {
  html {
    font-size: 18px;
  }
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1480px;
  }
}

@media all and (max-width: 1199px) {
  .btn-primary {
    min-width: auto;
  }
}

@media all and (max-width: 1023px) {
  html {
    font-size: 13px;
  }
}

@media all and (max-width: 991px) {
  .modal-lg,
  .modal-xl {
    max-width: 85%;
    margin: 1.75rem auto;
  }
  .min-h-24 {
    min-height: auto;
  }
}

@media all and (max-width: 767px) {
  .navbar {
    justify-content: flex-end;
  }
  .navbar-nav {
    background: rgba(0, 0, 0, 0.1);
    padding: 5px;
    flex-direction: row;
  }
  .nav-pills {
    background-color: transparent;
  }
  .resetBtn {
    margin-top: 5px;
  }
}

@media all and (max-width: 575px) {
  html {
    font-size: 13px;
  }
  .topLeftIcons {
    text-align: center;
  }
  .topHeader .topRight {
    text-align: center !important;
  }
  .topHeader .topRight a {
    display: inline-block;
  }
  .header .logo {
    align-items: flex-end;
    justify-content: center;
    margin: 0;
  }
  .header .navbar-nav .nav-item {
    display: flex;
  }
  .header .navbar-nav .nav-item a {
    padding: 0.5rem 0;
  }
  .header .navbar-nav .nav-item a i {
    /*     padding: 0.75rem;
 */
    font-size: 1.25rem;
  }
  .headRow label,
  .headRow label a,
  .resetBtn {
    margin-top: 0.5rem;
  }
  .btn.btn-primary {
    width: 100%;
    margin: 5px 0 !important;
    max-width: 100%;
  }
}

#applyReqBox {
  padding-top: 1rem;
}

.filter-box .btn-primary {
  margin-bottom: 0;
}

.swal2-styled.swal2-confirm {
  background-color: #003865 !important;
}

.swal2-styled.swal2-cancel {
  background-color: #003865 !important;
}

.removeCapital {
  text-transform: none !important;
}

.modal-dialog-centered.modal-dialog-scrollable
  .modal-content
  .modal-dialog
  .modal-dialog-centered {
  min-height: calc(100vh - 6.5rem) !important;
}
.modal-body {
  min-height: 75vh;
}
/* 
.modal-dialog-scrollable .modal-content .myModal {
  min-height: calc(100% - 6.5rem) !important;
}

.modal-dialog-centered::before .myModal .modal-content {
  min-height: calc(100% - 6.5rem) !important;
}

.modal-dialog-centered {
  min-height: calc(100% - 6.5rem) !important;
} */

[dir="rtl"] .pass-wrapper i {
  right: 90% !important;
}

.analysis-accordion img {
  width: 20px !important;
}

.resetFilter img{
  width: 20px !important;
}
