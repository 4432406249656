.input-validation-error {
  margin-top: 10px;
  border: 1px solid #ff0000;
  background-color: #ffeeee;
  padding: 2px 7px;
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.field-group {
  position: relative;
}

.demandDateValue {
  position: absolute;
  top: 25%;
}

.demandDateValue span {
  color: #000;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline-block;
}

.demandDateValue .l-icon {
  max-width: 1rem;
}

.card-header-search {
  margin-bottom: 1rem;
}

.searchInputIcon {
  display: block;
  padding: 0 0.75rem;
  margin: 0 0.5rem;
  margin-left: 90%;
}

.btnFileDelete {
  color: #901235;
  border: 0;
  background-color: transparent;
  font-weight: 500;
}

.view-attachment {
  background: #f6f1ee;
  padding: 10px 0px;
  margin: 5px 0;
}

.paging-block {
  margin: 2rem 0 0 0;
}

.paging-block .page-link {
  border: 0;
  color: #003865;
}

.paging-block .page-link:hover,
.paging-block .page-item.active .page-link {
  color: #fff;
  background-color: #003865;
  border-radius: 0.25rem;
}

.paging-block .page-item.active .page-link {
  background-color: #d8d8d8;
  cursor: default;
}

.noResult {
  padding: 1rem;
  text-align: center;
  background: #99AFC1 !important;
  margin: 1rem;
  color: #003865;
}

.noResult span {
  width: 100%;
}

/*
 * File
 */

/* Focus */

.file input:focus ~ .file-custom {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
}

.highcharts-drillup-button {
  display: contents;
}

.mapInfoBox {
  background-color: #003865;
  padding: 12px;
  border-radius: 15px;
  border: 0.075rem solid #ddd;
  color: #fff;
  text-align: center;
  opacity: 0.75;
}

.request-detail-control {
  display: block;
  border-radius: 1.5rem;
  border: 1px solid rgba(205, 194, 188, 0.5);
  color: #003865;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-clip: padding-box;
}

.admin-top-link {
  width: auto !important;
  padding: 0px 10px;
  border: 0 !important;
  font-weight: 500;
}

[dir="ltr"] input[type="email"],
[dir="ltr"] input.mobile-style {
  text-align: start !important;
}

[dir="rtl"] input[type="email"],
[dir="rtl"] input.mobile-style {
  text-align: end !important;
  direction: ltr;
}

figure.image img {
  width: 99%;
}

label.imageAttachmentHint.text-left {
  text-transform: none;
}

div._loading_overlay_overlay {
  position: fixed;
}

.pass-wrapper {
  position: relative;
  display: flex;
}

.pass-wrapper i {
  position: absolute;
  top: 25%;
  right: 5%;
}

.pass-wrapper i:hover {
  color: #00fcb6;
  cursor: pointer;
}
.maxSizeBtn {
  min-width: 12rem;
}
